import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import { ImAccessibility, ImPacman } from "react-icons/im";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <ImAccessibility/> About <strong className="purple">TOAN NGUYEN</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img style={{borderRadius: "10px",  maxHeight: "400px", border:"1px solid #3ec802"}} src="https://ways2germany.com/wp-content/uploads/2023/09/IMG_9888.jpeg" alt="about" className="img-fluid" />
          </Col>
        </Row>
        {/* <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />
      */}

        <h1 className="project-heading">
          <ImPacman/> My <strong className="purple">EXPERIENCES</strong>
        </h1> 
        <Toolstack />

        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
