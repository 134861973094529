import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../redux/apiSlice";

function Projects() {
  const dispatch = useDispatch();
  const { items, status } = useSelector((state) => state.data.projects);

  useEffect(() =>{
    dispatch(getAllProject());
  }, [dispatch]);

  function stripHtmlTagsAndLimitWords(str, wordLimit) {
    if (!str) return "";
    // Loại bỏ thẻ HTML
    const text = str.replace(/<\/?[^>]+(>|$)/g, "");
    // Tách từ và giới hạn số lượng từ
    const words = text.split(" ");
    return words.slice(0, wordLimit).join(" ") + (words.length > wordLimit ? "..." : "");
  }
  
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Projects and activities </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects and activities I've worked on...
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {status === 'loading' && <p>Loading...</p>}
          {status === 'succeeded' && items?.length > 0 ? (
            items.map((project) => (
              <Col md={3} className="project-card" key={project?.id}>
                <ProjectCard
                  imgPath={project?.jetpack_featured_media_url || 'https://ways2germany.com/wp-content/uploads/2023/09/cropped-lgog-test-1-1.png'}
                  isBlog={false}
                  title={project?.title?.rendered}
                  description={stripHtmlTagsAndLimitWords(project?.excerpt?.rendered, 15)}
                  demoLink={project?.link}
                />
              </Col>
            ))
          ) : (
            status === 'succeeded' && <p>No projects found</p>
          )}
          {status === 'failed' && <p>Error loading projects</p>}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
