import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Sales Manager - IT shoring services of EWERK Group",
          "Member Board of Directors Unitech DACH",
          "Founder of Ways2Germany",
          "Lecturer of Leipzig University & Vietnamese-German University",
          "Outsourcing",
          "IT Talents Vietnam",
          "Ways to Germany",
          "Opportunities for IT talents and IT Specialists",
          
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
