import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postQuestion } from "../../redux/apiSlice";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
          <Col md={5} style={{ paddingBottom: 20}}>
              <img
                // src={homeLogo}
                src="https://ways2germany.com/wp-content/uploads/2023/09/IMG_9888.jpeg"
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "400px", borderRadius:"50%", border:"1px solid green" }}
              />
            </Col>

            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hallo from Germany!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> TOAN NGUYEN</strong>
              </h1>

              {/* <h2 className="heading-name">
                <strong >FOUNDER OF WAYS2GERMANY</strong>
              </h2> */}

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
