import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {ImArrowDown, ImArrowDown2, ImCompass, ImMap } from "react-icons/im";
import { Link } from "react-router-dom";

function Toolstack() {
  return (
    <>
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop:"40px" }}>
        <Col xs={4} md={2} className="tech-icons">
        {/* <SiVisualstudiocode /> */}
        <Card.Img variant="top" src="https://ways2germany.com/wp-content/uploads/2023/09/EWERK_Logo_Kopf_gros.png" style={{
          width:"75px",
        }} alt="company" />
        <a
          href="https://ewerk.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color:"white",
            fontSize:"20px",
            textDecoration: "none"
          }}
        > EWERK Group
        </a>
      </Col>

      
      <Col xs={10} md={8} className="tech-icons">
        <blockquote className="blockquote mb-0">
        <strong className="purple">Sales Manager</strong><span>
          {" "} - Leipzig </span>
          <h6>( Sept 2024 - Present )</h6>
          <ul>
            <li className="about-activity">
              <span>We bring IT solutions and resources from Vietnam to Germany and to Europe to support and create values for the customers.</span>
            </li>
          </ul>

          <h6>-----</h6>
          <strong className="purple">Sourcing Manager / Project Manager</strong><span>
          {" "} - Leipzig </span>
          <h6>( May 2019 - Sept 2024 )</h6>
          <ul>
            <li className="about-activity">
              - Sourcing management: strategic sourcing planning, coordinating and managing outsourcing activities, 
              contracts and relationship with IT suppliers in Vietnam, India, Czech Republic, etc.
            </li>
            <li className="about-activity">
              - IT Project management: managing mixed internal & external teams of developers, testers and stakeholders within IT development projects.
            </li>
          </ul>

          <h6>-----</h6>
          <strong className="purple">Sourcing Coordinator</strong><span>
          {" "} - Leipzig </span>
          <h6>( Jan 2017 - Apr 2019 )</h6>
          <ul>
            <li className="about-activity">
              Sourcing management: coordinating and managing outsourcing activities, contracts and 
              relationship with IT suppliers in Vietnam, India, Czech Republic, etc.
            </li>
          </ul>

        </blockquote>
      </Col>
    </Row>
    <span style={{ 
          fontSize:"32px",
          textAlign: "left",
          color:"white",
          }}
          >
          <ImArrowDown/>
    </span>
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop:"40px" }}>
      <Col xs={4} md={2} className="tech-icons">
        {/* <SiVisualstudiocode /> */}
        <Card.Img variant="top" src="https://media.licdn.com/dms/image/v2/D560BAQE_nOYMGNtZDQ/company-logo_200_200/company-logo_200_200/0/1708056389446/unitech_dach_logo?e=1735171200&v=beta&t=Zozsmg5idvTvEZvHyhEx2u2EqqgFpi4aid6bgpgFd4g" style={{
          width:"35%",
        }} alt="company" />
        <a
          href="https://unitechdach.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color:"white",
            fontSize:"20px",
            textDecoration: "none"
          }}
        > Unitech DACH
        </a>
      </Col>
      <Col xs={10} md={8} className="tech-icons">
        <blockquote className="blockquote mb-0">
          <strong className="purple">Member Board of Directors</strong><span>
          {" "} - Vietnam </span>
          <h6>( Oct 2023 - Present )</h6>
          <ul>
            <li className="about-activity">
              Unitech DACH is a place where IT talents from Germany and Viet Nam 
              come under one roof to provide excellent outsourcing services to our clients in DACH region.
            </li>
          </ul>
        </blockquote>
      </Col>
    </Row>
    <span style={{ 
          fontSize:"32px",
          textAlign: "left",
          color:"white",
          }}
          >
          <ImArrowDown/>
    </span>
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop:"40px" }}>
      <Col xs={4} md={2} className="tech-icons">
        {/* <SiVisualstudiocode /> */}
        <Card.Img variant="top" src="https://ways2germany.com/wp-content/uploads/2023/09/cropped-lgog-test-1.png" style={{
          width:"50%",
        }} alt="company" />
                <a
          href="https://ways2germany.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color:"white",
            fontSize:"20px",
            textDecoration: "none"
          }}
        > Ways2Germany
        </a>
      </Col>
      <Col xs={10} md={8} className="tech-icons">
        <blockquote className="blockquote mb-0">
          <strong className="purple">Founder</strong><span>
          {" "} - Germany </span>
          <h6>( May 2023 - Present )</h6>
          <ul>
            <li className="about-activity">
            We, at Ways2Germany, specialize in providing IT manpower sourcing services from Vietnam to companies in Germany.
             With the aim of enhancing the quality of IT manpower and meeting the ever-growing demand for IT personnel in Germany, 
             we have established close partnerships with companies in both countries.
            </li>
          </ul>
        </blockquote>
      </Col>
    </Row>
    <span style={{ 
          fontSize:"32px",
          textAlign: "left",
          color:"white",
          }}
          >
          <ImArrowDown/>
    </span>
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop:"40px" }}>
      <Col xs={4} md={2} className="tech-icons">
        {/* <SiVisualstudiocode /> */}
        <Card.Img variant="top" src="https://ways2germany.com/wp-content/uploads/2024/09/article.png" style={{
          width:"35%",
        }} alt="company" />
                <a
          href="https://vgu.edu.vn/en/home"
          target="_blank"
          rel="noreferrer"
          style={{
            color:"white",
            fontSize:"20px",
            textDecoration: "none"
          }}
        > Vietnamese - Germany University
        </a>
      </Col>
      <Col xs={10} md={8} className="tech-icons">
        <blockquote className="blockquote mb-0">
          <strong className="purple">Lecturer</strong><span>
          {" "} - Ho Chi Minh, Vietnam </span>
          <h6>( Jan 2022 - Present )</h6>
            <span className="about-activity">
            Modules: Global sourcing, Marketing & Market Research
            </span>
        </blockquote>
      </Col>
    </Row>
    <span style={{ 
          fontSize:"32px",
          textAlign: "left",
          color:"white",
          }}
          >
          <ImArrowDown/>
    </span>
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop:"40px" }}>
      <Col xs={4} md={2} className="tech-icons">
        {/* <SiVisualstudiocode /> */}
        <Card.Img variant="top" src="https://ways2germany.com/wp-content/uploads/2023/09/2560px-Universitat_Leipzig_logo.svg_.png" style={{
          width:"100%",
        }} alt="company" />
      <a
          href="https://www.uni-leipzig.de/en"
          target="_blank"
          rel="noreferrer"
          style={{
            color:"white",
            fontSize:"20px",
            textDecoration: "none"
          }}
        > Leipzig University
        </a>
      </Col>
      <Col xs={10} md={8} className="tech-icons">
        <blockquote className="blockquote mb-0">
          <strong className="purple">Guest Lecturer</strong><span>
          {" "} - Leipzig </span>
          <h6>( Jan 2021 - Present )</h6>
          <span className="about-activity">
            Module: Global sourcing - Supplier development & internationalization of SMEs
          </span>
        </blockquote>
      </Col>
    </Row>

    </>

  );
}

export default Toolstack;
