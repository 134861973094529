// import React, { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.svg";
// import Tilt from "react-parallax-tilt";
// import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
// import { ImArrowDown2, ImArrowDownLeft, ImArrowDownLeft2, ImArrowLeft2, ImArrowRight, ImMail, ImMail2, ImMail3, ImMail4, ImPhone, ImPhoneHangUp, ImPointDown } from "react-icons/im";
// import { useDispatch, useSelector } from "react-redux";
// import { postQuestion } from "../../redux/apiSlice";

// function Home2() {

//   const dispatch = useDispatch();
//   const { items, status, error } = useSelector((state) => state.data);
//   const [inputQuestion, setInputQuestion] = useState('');

//   const handleInputChange = (e) => {
//     setInputQuestion(e.target.value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (inputQuestion.trim()) {
//       dispatch(postQuestion(inputQuestion));
//       setInputQuestion('');
//     }
//     console.log('====================================');
//     console.log(items[0]?.candidates[0]?.content?.parts[0]?.text);
//     console.log('====================================');
//   };

//   return (
//     <Container fluid className="home-about-section" id="about">
//       <Container>
//         {/* EXPERIENCES */}
//         <h3 style={{fontWeight: 600}} className="purple">
//           Toan developed a bot to answer questions about himself; feel free to ask the bot anything:
//         </h3>

//         <span style={{ 
//           fontSize:"32px",
//           textAlign: "left",
//           color:"white",
//           }}
//           >
//           <ImArrowDown2/>
//         </span>

//         <div style={{
//           marginTop: "100px",
//           }}>
//           <h4 style={{
//             fontWeight: 600,
//             // fontSize: 20,
//             color: "#3a3d41",
//             padding: "20px 10px",
//             backgroundColor:"white",
//             borderRadius:"5px",
//             width: "600px",
//             }} className="purple">
//             Hi there, welcome back! How are you doing today?
//           </h4>

//           {/* Response */}
//           <div>
//             {status === 'loading' && <p>Loading...</p>}
//             {status === 'failed' && <p>Error: {error}</p>}
//             <span style={{color:"white"}}>
//               {items[0]?.candidates[0]?.content?.parts[0]?.text}
//             </span>
//           </div>
//          {/* Response */}

//           <div id="waitingMessage" className="chat_assistantMessage__rdpnJ chat_glassBox__Qlg_r chat_waitingMessage__MeUlw">
//             <span style={{fontSize: "40px", color:"white"}}>&gt;_</span>
//           </div>

//           <form style={{
//             display: "flex",
//             // alignItems: "center",
//             // padding: "20px",
//           }} onSubmit={handleSubmit}>
//             <input style={{
//               width: "100%",
//               height: "60px",
//               borderRadius:"10px 0 0 10px",
//               padding: "20px",
//               fontSize: "20px",
//               border: "1px solid green",
//               color:"white",
//               backgroundColor: "rgba(41, 101, 10, 0.573)"
//             }} 
//             type="text" 
//             placeholder="Ask me anything you want" 
//             value={inputQuestion}
//             onChange={handleInputChange}
//             />

//             <button style={{
//               height: "60px",
//               borderRadius:"0 10px 10px 0",
//               padding: "0 10px 0 10px",
//               // fontSize: "20px",
//               border: "none",
//               backgroundColor: "white",
//               color: "black",
//               cursor: "pointer",
//               border: "none",
//               marginLeft: "5px"
//               // position: "absolute",

//               }} type="submit">
//                 <ImArrowLeft2/>
//                 {" "}Ask
//             </button>
//           </form>

//         </div>

//         <span style={{ 
//           fontSize:"32px",
//           textAlign: "left",
//           color:"white",
//           }}
//           >
//           <ImArrowDown2/>
//         </span>

//         {/* <Row>
//           <Col md={8} className="home-about-description">
//             <p className="home-about-body">
//             Founder of Ways2Germany (www.ways2germany.com) 
//               <i>
//                 <b className="purple"> (May 2023 – ongoing)</b>
//               </i>
//               <br />
//               -	A portal to connect IT talents from Vietnam to Germany & to Europe
//               <br />
//               -	A partner of the project “IT Talent exchange 2023 & 2024” with Uni Leipzig
//               <br />
//             </p>
//           </Col>
//           <Col md={4} className="myAvtar">
//             <Tilt>
//               <img src="https://ways2germany.com/wp-content/uploads/2023/09/cropped-lgog-test-1-1.png" className="img-fluid" alt="avatar" />
//             </Tilt>
//           </Col>
//         </Row> */}

//         {/* <Row>
//         <Col md={4} className="myAvtar">
//             <Tilt>
//               <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fvi.m.wikipedia.org%2Fwiki%2FT%25E1%25BA%25ADp_tin%3ARMIT_University_Logo.svg&psig=AOvVaw3M_YPgEvwKEy0YjnBdrsz1&ust=1726972582537000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCICh9auA04gDFQAAAAAdAAAAABAE" className="img-fluid" alt="avatar" />
//             </Tilt>
//           </Col>
//           <Col md={8} className="home-about-description">
//             <p className="home-about-body">
//             RMIT International University (Vietnam) 
//               <i>
//                 <b className="purple"> (Dec 2014 – Sept 2015)</b>
//               </i>
//               <br />
//               <span className="purple">*	Senior Student Recruitment Consultant</span>
              
//               <br />
//               •	Planning and implementing recruitment campaigns for post-graduate programs (MBA, EMBA).
//               •	Directly consulting and recruiting 60+ post-graduate students for 03 intakes.
//               <br />
//               •	Lecturing 10+ workshops and short courses of skill development and Uni-transition for Pre-university students.
//               <br />
//               <br />
//             </p>
//           </Col>
          
//         </Row> */}

//         {/* EXPERIENCES */}
//         {/* <Row>
//           <Col md={4} className="myAvtar">
//             <Tilt>
//               <img src="https://ways2germany.com/wp-content/uploads/2023/09/cropped-lgog-test-1-1.png" className="img-fluid" alt="avatar" />
//             </Tilt>
//           </Col>
//           <Col md={8} className="home-about-description">
//             <p className="home-about-body">
//             I am fluent in classics like
//               <i>
//                 <b className="purple"> C++, Javascript and Go. </b>
//               </i>
//               <br />
//             We, at Ways2Germany, specialize in providing IT manpower
//             sourcing services from Vietnam to companies in Germany.
//             With the aim of enhancing the quality of IT manpower and 
//             meeting the ever-growing demand for IT personnel in Germany, 
//             we have established close partnerships with companies in both countries.
//               <br />
//               Whenever possible, I also apply my passion for developing products
//               with <b className="purple">Node.js</b> and
//               <i>
//                 <b className="purple">
//                   {" "}
//                   Modern Javascript Library and Frameworks
//                 </b>
//               </i>
//               &nbsp; like
//               <i>
//                 <b className="purple"> React.js and Next.js</b>
//               </i>
//             </p>
//           </Col>
//         </Row> */}


//         <Row>
//           <Col md={12} className="home-about-social">
//             <h1>
//               <ImPhone/> {" "}
//               CONTACT ME
//             </h1>
//             <p>
//               You can click {" "}<ImArrowRight/>{" "}
//               <a style={{textDecoration: "none"}} className="purple" href="https://it.ways2germany.com/#appointment">BOOK AN APPOINTMENT</a>
//               {" "} with me
//             </p>


//             <p style={{
//               justifyContent: "center",
//               display: "flex",
//               alignItems: "center",
//             }}>
//               <ImMail/>
//               <a style={{textDecoration: "none", paddingLeft: "10px"}} className="purple" href="mailto:toan@ways2germany.com">toan@ways2germany.com</a>
//             </p>

//             <p style={{
//               justifyContent: "center",
//               display: "flex",
//               alignItems: "center",
//             }}>
//               <a style={{textDecoration: "none", paddingLeft: "10px"}} className="purple" href="https://maps.app.goo.gl/fiyyc1sPvzF1oDXY7">Ritterstraße 9/13, 04109 Leipzig-Mitte, Germany</a>
//             </p>

//             <ul className="home-about-social-links">
//               {/* <li className="social-icons">
//                 <a
//                   href="https://github.com/soumyajit4419"
//                   target="_blank"
//                   rel="noreferrer"
//                   className="icon-colour  home-social-icons"
//                 >
//                   <AiFillGithub />
//                 </a>
//               </li> */}
//               {/* <li className="social-icons">
//                 <a
//                   href="https://twitter.com/Soumyajit4419"
//                   target="_blank"
//                   rel="noreferrer"
//                   className="icon-colour  home-social-icons"
//                 >
//                   <AiOutlineTwitter />
//                 </a>
//               </li> */}
//               <li className="social-icons">
//                 <a
//                   href="https://www.linkedin.com/in/chi-toan-nguyen/"
//                   target="_blank"
//                   rel="noreferrer"
//                   className="icon-colour  home-social-icons"
//                 >
//                   <FaLinkedinIn />
//                 </a>
//               </li>
//               <li className="social-icons">
//                 <a
//                   href="https://www.facebook.com/nchitoan"
//                   target="_blank"
//                   rel="noreferrer"
//                   className="icon-colour home-social-icons"
//                 >
//                   <FaFacebookF />
//                 </a>
//               </li>
//             </ul>
//           </Col>
//         </Row>
        
//       </Container>
//     </Container>
//   );
// }
// export default Home2;


import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { ImArrowDown2, ImArrowLeft2, ImMail, ImPhone } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject, postQuestion } from "../../redux/apiSlice";
import TypingEffect from '../../components/TypingEffect'; // Nhập component TypingEffect
import Projects from "../Projects/Projects";

function Home2() {
  const dispatch = useDispatch();
  const { responses, chatStatus } = useSelector((state) => state.data.chat);
  const { items, status } = useSelector((state) => state.data.projects);
  const [inputQuestion, setInputQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]); // State để lưu câu hỏi và câu trả lời
  const [typingIndex, setTypingIndex] = useState(null); // State để theo dõi câu trả lời nào đang gõ
  const [posts, setPosts] = useState([]); // Khai báo state cho posts

  // const handleInputChange = (e) => {
  //   setInputQuestion(e.target.value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (inputQuestion.trim()) {
  //     const newQuestion = { type: 'question', text: inputQuestion };
  //     setChatHistory((prev) => [...prev, newQuestion]); // Thêm câu hỏi vào chat history

  //     dispatch(postQuestion(inputQuestion)); // Gửi câu hỏi mới
  //     setInputQuestion(''); // Xóa câu hỏi đầu vào
  //   }
  // };


  // useEffect(() => {
  //   if (
  //     chatStatus === 'succeeded' &&
  //     responses?.length > 0 &&
  //     responses[responses.length - 1]?.candidates?.length > 0 &&
  //     responses[responses.length - 1]?.candidates[0]?.content?.parts?.length > 0
  //   ) {
  //     const newResponseText = responses[responses.length - 1].candidates[0].content.parts[0].text;
  //     const newResponse = { type: 'response', text: newResponseText };
  //     setChatHistory((prev) => [...prev, newResponse]);
  //     setTypingIndex(chatHistory?.length);
  //   }
  // }, [chatStatus, responses]);

  const handleInputChange = (e) => setInputQuestion(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputQuestion.trim()) {
      setChatHistory((prev) => [...prev, { type: 'question', text: inputQuestion }]);
      dispatch(postQuestion(inputQuestion));
      setInputQuestion('');
    }
  };
  
  useEffect(() => {
    if (chatStatus === 'succeeded' && responses?.length > 0) {
      const lastResponse = responses[responses.length - 1];
      const newResponseText = lastResponse?.candidates?.[0]?.content?.parts?.[0]?.text;
      if (newResponseText) {
        setChatHistory((prev) => [...prev, { type: 'response', text: newResponseText }]);
        setTypingIndex(chatHistory.length);
      }
    }
  }, [chatStatus, responses]);

  const formatResponseText = (text) => {
    const parts = text.split('\n').map((part, index) => <p style={{fontSize: '20px'}} key={index}>{part}</p>);
    return <div>{parts}</div>;
  };

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <h3 style={{ fontWeight: 600 }} className="purple">
          Toan developed a bot to answer questions about himself; feel free to ask the bot anything:
        </h3>

        <span style={{ fontSize: "32px", textAlign: "left", color: "white" }}>
          <ImArrowDown2 />
        </span>

        <div style={{ marginTop: "100px" }}>
          <h5 style={{
            fontWeight: 600,
            color: "#3a3d41",
            padding: "20px 10px",
            backgroundColor: "white",
            borderRadius: "5px",
            width: "100%",
            display: "flex",
          }} className="purple">
            Hi there, welcome back! How are you doing today?
          </h5>

          {/* Hiển thị lịch sử chat */}
          <div style={{ marginTop: "20px", overflowY: "auto", borderRadius: "5px", padding: "10px", color: "white" }}>
            {chatHistory.map((chat, index) => (
              <div key={index} style={{ margin: "10px 0", textAlign: chat.type === 'question' ? 'right' : 'left' }}>
                <strong style={{ fontSize: "20px", color: chat.type === 'question' ? 'red' : 'green' }}>
                  {chat.type === 'question' ? 'You:' : 'AI Bot:'}
                </strong>
                {chat.type === 'response' && typingIndex === index ? ( // Kiểm tra xem câu trả lời này có đang được gõ không
                  <TypingEffect text={chat.text} onComplete={() => setTypingIndex(null)} />
                ) : (
                  <div style={{ lineHeight: "30px", letterSpacing: "1px" }}>
                    {formatResponseText(chat.text)}
                  </div>
                )}
              </div>
            ))}
          </div>

          <form style={{ display: "flex", marginTop: "50px", marginBottom:"150px" }} onSubmit={handleSubmit}>
            <input style={{
              width: "100%",
              height: "60px",
              borderRadius: "10px 0 0 10px",
              padding: "20px",
              fontSize: "20px",
              border: "1px solid green",
              color: "white",
              backgroundColor: "rgba(41, 101, 10, 0.573)"
            }} 
            type="text" 
            placeholder="Ask me anything you want..." 
            value={inputQuestion}
            onChange={handleInputChange}
            />

            <button style={{
              height: "60px",
              borderRadius: "0 10px 10px 0",
              padding: "0 10px",
              border: "none",
              backgroundColor: "white",
              color: "black",
              cursor: "pointer",
              marginLeft: "5px"
            }} type="submit">
              <ImArrowLeft2 /> Ask
            </button>
          </form>
        </div>

        <span style={{ fontSize: "32px", textAlign: "left", color: "white" }}>
          <ImArrowDown2 />
        </span>

        <Projects/>

        <span style={{ fontSize: "32px", textAlign: "left", color: "white" }}>
          <ImArrowDown2 />
        </span>

        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              <ImPhone /> CONTACT ME
            </h1>
            <p>
              You can click <ImArrowDown2 /> <a style={{ textDecoration: "none" }} className="purple" href="https://it.ways2germany.com/#appointment">BOOK AN APPOINTMENT</a>
            </p>

            <p style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}>
              <ImMail />
              <a style={{ textDecoration: "none", paddingLeft: "10px" }} className="purple" href="mailto:toan@ways2germany.com">toan@ways2germany.com</a>
            </p>

            <p style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}>
              <a style={{ textDecoration: "none", paddingLeft: "10px" }} className="purple" href="https://maps.app.goo.gl/fiyyc1sPvzF1oDXY7">Ritterstraße 9/13, 04109 Leipzig-Mitte, Germany</a>
            </p>

            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/chi-toan-nguyen/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/nchitoan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaFacebookF />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
