import React, { useEffect, useState } from 'react';

const TypingEffect = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setDisplayText((prev) => prev + text.charAt(index));
      index += 1;
      if (index === text.length) {
        clearInterval(interval);
        onComplete(); // Gọi hàm khi hoàn thành việc gõ
      }
    }, 10); // Thay đổi thời gian này để điều chỉnh tốc độ gõ

    return () => clearInterval(interval); // Dọn dẹp
  }, [text, onComplete]);

  // Hàm định dạng văn bản tương tự như trong Home2
  const formatResponseText = (text) => {
    const parts = text.split('\n').map((part, index) => <p style={{ fontSize: '20px' }} key={index}>{part}</p>);
    return <div>{parts}</div>;
  };

  // Hiển thị văn bản đã gõ
  return (
    <div style={{
      lineHeight: "30px",
      letterSpacing: "1px",
    }}>
      {formatResponseText(displayText)}
    </div>
  );
};

export default TypingEffect;
