import React from "react";
import Card from "react-bootstrap/Card";
import { ImBullhorn , ImSpinner2 } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello, I'm <span className="purple">Toan Nguyen. </span>I'm a <span className="purple">proactive business enthusiast and dedicated researcher </span>
            with a keen focus on international business and global sourcing, particularly in the realms of
            <span className="purple"> Germany 🇩🇪 and Vietnam 🇻🇳</span>
            <br />
            <br />
            Here's a snapshot of my professional journey:
            <br />
          </p>
          <ul>
            <li className="about-activity">
              <ImSpinner2 /> Over 12 years of hands-on experience in business development, 
              sourcing management, project management, marketing, consulting, and education.
            </li>
            <li className="about-activity">
              <ImSpinner2 /> Completed my PhD at the University of Leipzig, 
              specializing in Global Value Chains & SME Development in 2020.
            </li>
            <li className="about-activity">
              <ImSpinner2 /> Earned an MBA from the University of Applied Sciences Northwestern Switzerland in 2014.
            </li>
            <li className="about-activity">
              <ImSpinner2 /> Graduated from Bach Khoa University HCM City in 2011.
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            <ImBullhorn/> "If you cannot do great things, do small things in a great way."
          </p>
          <footer className="blockquote-footer">Toan Nguyen</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
