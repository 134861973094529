// // src/redux/apiSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// // Đặt URL API của bạn
// const API_URL = 'http://localhost:8080/api/';
// const API_PHP_WP = 'https://ways2germany.com/wp-json/wp/v2/';

// // Tạo async thunk để gửi câu hỏi đến chatbot
// export const postQuestion = createAsyncThunk('data/postQuestion', async (question) => {
//   const response = await axios.post(`${API_URL}chatbot/ask`, {
//     question, // Gửi câu hỏi dưới dạng JSON
//   });

//   return response.data; // Giả định trả về JSON chứa câu trả lời
// });

// export const getAllProject = createAsyncThunk('data/getAllProject', async () => {
//   // bằng ID thực tế của 'project_w2g' và 'project_toan_nguyen'.
//   const categoryIds = '121';// ID Cate cua Toan_Nguyen_Project
  
//   // Gửi yêu cầu GET để lấy tất cả các bài viết thuộc các category đã chỉ định
//   const response = await axios.get(`${API_PHP_WP}posts?categories=`, {
//     params: {
//       categories: categoryIds,
//     },
//   });
  
//   // console.log("Projects:", response?.data);
//   return response?.data;
// });


// // Tạo slice
// const apiSlice = createSlice({
//   name: 'data',
//   // initialState: { items: [], status: 'idle', error: null },
//   initialState : {
//     projects: { items: [], status: 'idle' },
//     chat: { responses: [], chatStatus: 'idle' },
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//     // Case cho post questions
//       .addCase(postQuestion.pending, (state) => {
//         state.chat.chatStatus = 'loading';
//       })
//       .addCase(postQuestion.fulfilled, (state, action) => {
//         state.chat.responses.push(action.payload); // Giả định payload là một đối tượng có thuộc tính 'response'
//         state.chat.chatStatus = 'succeeded';
//       })
//       .addCase(postQuestion.rejected, (state, action) => {
//         state.chat.chatStatus = 'failed';
//         state.chat.error = action.error.message;
//       })
//       // Case cvho get project
//       .addCase(getAllProject.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(getAllProject.fulfilled, (state, action) => {
//         state.items = action.payload;
//         state.status = 'succeeded';
//       })
//       .addCase(getAllProject.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       });
//   },
// });

// // Xuất reducer
// export default apiSlice.reducer;




// src/redux/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Đặt URL API của bạn
const API_URL = 'https://w2g-ai-assistant-for-project-bot.onrender.com/api/';
const API_PHP_WP = 'https://ways2germany.com/wp-json/wp/v2/';

// Tạo async thunk để gửi câu hỏi đến chatbot
export const postQuestion = createAsyncThunk('data/postQuestion', async (question) => {
  const response = await axios.post(`${API_URL}chatbot/ask`, { question });
  return response.data; // Giả định trả về JSON chứa câu trả lời
});

export const getAllProject = createAsyncThunk('data/getAllProject', async () => {
  const categoryIds = '121';
  const response = await axios.get(`${API_PHP_WP}posts`, {
    params: { categories: categoryIds },
  });
  return response.data;
});

const apiSlice = createSlice({
  name: 'data',
  initialState: {
    projects: { items: [], status: 'idle', error: null },
    chat: { responses: [], chatStatus: 'idle', error: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Xử lý cho postQuestion
      .addCase(postQuestion.pending, (state) => {
        state.chat.chatStatus = 'loading';
      })
      .addCase(postQuestion.fulfilled, (state, action) => {
        state.chat.responses.push(action.payload);
        state.chat.chatStatus = 'succeeded';
      })
      .addCase(postQuestion.rejected, (state, action) => {
        state.chat.chatStatus = 'failed';
        state.chat.error = action.error.message;
      })
      // Xử lý cho getAllProject
      .addCase(getAllProject.pending, (state) => {
        state.projects.status = 'loading';
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        state.projects.items = action.payload;
        state.projects.status = 'succeeded';
      })
      .addCase(getAllProject.rejected, (state, action) => {
        state.projects.status = 'failed';
        state.projects.error = action.error.message;
      });
  },
});

export default apiSlice.reducer;
